import {handleErrorWithSentry} from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

/**
 * @see https://vitejs.dev/guide/env-and-mode.html#env-files
 */
if (import.meta.env.SENTRY_DSN ?? null) {
    const environment = import.meta.env.SENTRY_ENVIRONMENT ?? import.meta.env.MODE ?? import.meta.env.NODE_ENV ?? 'development';
    Sentry.init({
        dsn: import.meta.env.SENTRY_DSN,
        environment: environment,
        release: import.meta.env.SENTRY_RELEASE ?? null,
        tracesSampleRate: environment === 'production' ? 1.0 : 0.0,
        integrations: [
            Sentry.browserTracingIntegration({
                shouldCreateSpanForRequest: (url) => {
                    return new URL(url).pathname !== '/socket/';
                },
            }),
        ],
    });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
